.mainContainer {
  background-color: #050a02;
  position: relative;
  /* width: 100%; */
  background: url("../public/images/heroBGn.svg");
  height: 100%;
  width: 100%;
  /* min-height: 100vh; */
  background-position: top center;

  background-repeat: no-repeat;
  overflow: hidden;
}

body {
  background-color: #050a02;
}

.joinBg {
  /* position: absolute; */
  position: relative;
  width: 100%;
  bottom: 0px;
  background: url("../public/images/heroBGn.svg");

  background-position: bottom center;

  background-repeat: no-repeat;
  /* overflow: hidden; */
  /* height: 100%; */
}

/* 
.modal{
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
} */

@media (max-width: 1560px) {
}

@media (max-width: 1280px) {
    .mainContainer {
    background-color: #050a02;
    position: relative;
    /* width: 100%; */
    background: url("../public/images/heroBGM.svg");
    height: 100%;
    width: 100%;
    /* min-height: 100vh; */
    background-position: top center;
    background-size: 100% 1023px;
    background-repeat: no-repeat;
  }
}

@media (max-width: 1100px) {
}

@media (max-width: 780px) {
  .joinBg {
    /* position: absolute; */
    position: relative;
    width: 100%;
    bottom: 0px;
    background: url("../public/images/joinBGM.svg");
    background-size: 100% 53%;
    background-position: bottom center;

    background-repeat: no-repeat;
    /* overflow: hidden; */
    /* height: 100%; */
  }

  .mainContainer {
    background-color: #050a02;
    position: relative;
    /* width: 100%; */
    background: url("../public/images/heroBGM.svg");
    height: 100%;
    width: 100%;
    /* min-height: 100vh; */
    background-position: top center;
    background-size: 100% 1023px;
    background-repeat: no-repeat;
  }
}

@media (max-width: 400px) {
  .joinBg {
    /* position: absolute; */
    position: relative;
    width: 100%;
    bottom: 0px;
    background: url("../public/images/joinBGM.svg");
    background-size: 100% 70%;
    background-position: bottom center;

    background-repeat: no-repeat;
    /* overflow: hidden; */
    /* height: 100%; */
  }
}

@media (min-width: 1920px) {
  .mainContainer {
    background-color: #050a02;
    position: relative;
    /* width: 100%; */
    background: url("../public/images/heroBGn.svg");
    height: 100%;
    width: 100%;
    /* min-height: 100vh; */
    background-size: 100% 1300px;
    background-position: top center;

    background-repeat: no-repeat;
  }

  .joinBg {
    /* position: absolute; */
    position: relative;
    width: 100%;
    bottom: 0px;
    background: url("../public/images/joinBG1.svg");

    background-position: bottom center;

    background-repeat: no-repeat;
    /* overflow: hidden; */
    /* height: 100%; */
    background-size: 100% 1370px;
  }

  .animationContainer {
    display: unset;

    bottom: 768px;
    left: 10.233vw;

    width: 3.1vw;
    height: 55px;
    position: absolute;
    opacity: 0.7 !important;
  }
  /* .light {
    filter: blur(40px);
    width: 100px;
    height: 50px;
    top: 50%;
    left: 50%;
    border-radius: 50%;
    margin: -285px 0 0 -70px;
    border: 300px solid transparent;
    border-right: none;
    opacity: 0.3 !important;
    position: absolute;
    animation: lightAnimation 2s linear forwards;

    transform: rotate(183deg);
  } */

  /* @keyframes lightAnimation {
    0% {
      width: 0px;
      top: 0;
      opacity: 0;
      border-left: 0px;
    }
    10% {
      width: 5px;
    }
    20% {
      width: 10px;
    }
    30% {
      width: 20px;
    }
    40% {
      width: 25px;
    }
    50% {
      width: 30px;
    }
    60% {
      width: 35px;
    }
    70% {
      width: 40px;
      border-left: 0px;
    }
    100% {
      opacity: 0.3;
      border-left: 61.458vw solid rgb(180, 180, 180);
    }
  } */

  @keyframes scanner-loop {
    0% {
      top: 0;
      width: 20px;
    }

    30% {
      top: 0;
      width: 20px;
    }
    100% {
      top: 0;
      width: 3.3vw;
      /* width: 3vw !important; */
    }
  }

  .animation {
    /* transform: skewY(20deg); */
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: -8px;

    /* padding: 0.095vw; */
    overflow: hidden;
    animation: scanner-loop 2s linear forwards;

    clip-path: polygon(
      0% 0%,
      0% 100%,
      /* calc(0% + 8px) 100%,
    calc(0% + 8px) calc(0% + 8px),
    calc(100% - 8px) calc(0% + 8px),
    calc(100% - 8px) calc(100% - 8px),
    calc(0% + 8px) calc(100% - 8px), */
        0% 100%,
      100% 100%,
      100% 0%
    );

    clip-path: polygon(0 17%, 100% 0, 100% 100%, 0 87%);
    clip-path: polygon(0 11%, 100% 0, 100% 100%, 0 86%);
  }

  .animation:before {
    content: "";
    display: block;
    background: linear-gradient(
      to right,
      hsl(75, 85%, 40%),
      hsl(75, 85%, 70%),
      #c2ea2b,
      hsl(75, 85%, 40%),
      hsl(75, 85%, 70%),
      #c2ea2b,
      hsl(75, 85%, 40%)
    );
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 400%;
    animation: dynamic-border-animation 2s linear infinite;
    transform: translateX(-50%);
  }

  @keyframes dynamic-border-animation {
    to {
      transform: translateX(0%);
    }
  }

  .animationContainerT {
    display: unset;

    top: 305px;
    left: 9.45vw;

    /* :180px ; */
    width: 50px;
    height: 55px;
    position: absolute;
    opacity: 0.7 !important;
  }
  .lightT {
    filter: blur(40px);
    width: 100px;
    height: 50px;
    top: 50%;
    left: 50%;
    border-radius: 50%;
    margin: -285px 0 0 -70px;
    border: 300px solid transparent;
    border-right: none;
    /* border-left: 200px solid rgb(180, 180, 180); */
    opacity: 0.3 !important;
    position: absolute;
    animation: lightAnimationT 2s linear forwards;

    transform: rotate(183deg);
  }

  @keyframes lightAnimationT {
    0% {
      width: 0px;
      top: 0;
      opacity: 0;
      border-left: 0px;
    }
    10% {
      width: 5px;
    }
    20% {
      width: 10px;
    }
    30% {
      width: 20px;
    }
    40% {
      width: 25px;
    }
    50% {
      width: 30px;
    }
    60% {
      width: 35px;
    }
    70% {
      width: 40px;
      border-left: 0px;
    }
    100% {
      opacity: 0.3;
      border-left: 61.458vw solid rgb(180, 180, 180);
    }
  }

  @keyframes scanner-loop {
    0% {
      top: 0;
      width: 20px;
    }

    30% {
      top: 0;
      width: 20px;
    }
    100% {
      top: 0;
      width: 3.1vw;
    }
  }

  .animationT {
    /* transform: skewY(20deg); */
    position: absolute;
    width: 3.1vw ;

    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: -8px;
    /* padding: 0.095vw; */
    overflow: hidden;
    animation: scanner-loop 2s linear forwards;

    clip-path: polygon(
      0% 0%,
      0% 100%,
      /* calc(0% + 8px) 100%,
    calc(0% + 8px) calc(0% + 8px),
    calc(100% - 8px) calc(0% + 8px),
    calc(100% - 8px) calc(100% - 8px),
    calc(0% + 8px) calc(100% - 8px), */
        0% 100%,
      100% 100%,
      100% 0%
    );

    clip-path: polygon(0 17%, 100% 0, 100% 100%, 0 87%);
    clip-path: polygon(0 11%, 100% 0, 100% 100%, 0 86%);
  }

  .animationT:before {
    content: "";
    display: block;
    background: linear-gradient(
      to right,
      hsl(75, 85%, 40%),
      hsl(75, 85%, 70%),
      #c2ea2b,
      hsl(75, 85%, 40%),
      hsl(75, 85%, 70%),
      #c2ea2b,
      hsl(75, 85%, 40%)
    );
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 400%;
    animation: dynamic-border-animation 2s linear infinite;
    transform: translateX(-50%);
  }

  @keyframes dynamic-border-animation {
    to {
      transform: translateX(0%);
    }
  }
}

@media (min-width: 2200px) {
  .animationT {
    /* padding: 0.205vw; */
  }
  .animation {
    /* transform: skewY(20deg); */
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: -8px;
    /* padding: 0.265vw; */
    overflow: hidden;
    animation: scanner-loop 2s linear forwards;

    clip-path: polygon(
      0% 0%,
      0% 100%,

      0% 100%,
      100% 100%,
      100% 0%
    );

    clip-path: polygon(0 17%, 100% 0, 100% 100%, 0 87%);
    clip-path: polygon(0 11%, 100% 0, 100% 100%, 0 86%);
  }
}

@media (min-width: 2300px) {
  .animationT {
    /* padding: 0.245vw; */
  }
}

@media (min-width: 2400px) {
  .animationT {
    /* padding: 0.275vw; */
    
  }
   .animationContainer {
    display: unset;

    bottom: 767px;
    left: 10.133vw;

    /* :180px ; */
    width: 3.1vw;
    height: 55px;
    position: absolute;
    opacity: 0.7 !important;
  }
}