.mainContainer {
  display: flex;
  justify-content: center;
  /* height: 900px; */
  padding-top: 237px;
  margin-top: -100px;
  padding-bottom: 200px;

  overflow: hidden;
}

.subContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
  max-width: 1440px;
}

.GradientL {
  position: absolute;
  left: -5%;
  bottom: 35%;
  width: 314.5px;
  height: 187px;
  /* border-radius: 30%; */
  background: #caff00;
  filter: blur(271.9999694824219px);
}

.GradientR {
  position: absolute;
  right: -15%;
  top: 25%;
  /* border-radius: 314.5px; */
  background: #c2ea2b;
  filter: blur(271.9999694824219px);
  width: 314.5px;
  height: 187px;
}

.textContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.mainHeading {
  font-size: 72px;
  font-family: TelegraphLight;
  color: #ffff;
}
.mainHeading span {
  color: #c2ea2b;
}
.subHeading {
  color: #fff;
  font-family: TelegraphReg;
  max-width: 580px;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 38px; /* 158.333% */
  text-transform: capitalize;
  text-align: center;
  margin-bottom: 50px;
}

.videoOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  pointer-events: none; /* Allows clicks to go through to the video */
  transition: opacity 0.3s ease-in-out; /* Smooth transition */
}

.video {
  /* margin-top: 50px; */
  position: relative;
  overflow: hidden;
  width: 100%;
  border-radius: 20px;
}
.video video {
  width: 100%;
  height: 100%;
}
@media (max-width: 1480px) {
  .mainContainer {
    display: flex;
    justify-content: center;
    /* height: 900px; */
    padding-top: 223px;
    margin-top: -100px;
    padding-bottom: 150px;

    overflow: hidden;
  }
  .GradientL {
    position: absolute;
    left: 0%;
    bottom: 40%;
    width: 20.38vw;
    height: 127px;
    /* border-radius: 30%; */
    background: #caff00;
    filter: blur(271.9999694824219px);
  }

  .GradientR {
    position: absolute;
    right: -5%;
    top: 25%;
    /* border-radius: 314.5px; */
    background: #c2ea2b;
    filter: blur(271.9999694824219px);
    width: 20.38vw;
    height: 127px;
  }
}

@media (max-width: 1024px) {
  .mainContainer {
    display: flex;
    justify-content: center;
    padding-top: 223px;
    margin-top: -100px;
    padding-bottom: 150px;
    overflow: hidden;
  }
  .GradientL {
    position: absolute;
    left: 0%;
    bottom: 40%;
    width: 20.38vw;
    height: 127px;
    /* border-radius: 30%; */
    background: #caff00;
    filter: blur(271.9999694824219px);
  }

  .GradientR {
    position: absolute;
    right: -5%;
    top: 25%;
    /* border-radius: 314.5px; */
    background: #c2ea2b;
    filter: blur(271.9999694824219px);
    width: 20.38vw;
    height: 127px;
  }
}
@media (max-width: 780px) {
  .mainContainer {
    display: flex;
    justify-content: center;
    margin-top: 110px;
    margin-top: -100px;
    padding-bottom: 120px;
    overflow: hidden;
  }

  .subContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%;

    /* width: 90%; */
  }
  .GradientL {
    /* display: none; */
    position: absolute;
    left: -300px;
    bottom: 35%;
    width: 193.859px;
    height: 115.268px;
    /* border-radius: 30%; */
    background: #caff00;
    filter: blur(167.66195678710938px);
  }

  .GradientR {
    /* display: none; */
    position: absolute;
    right: -300px;
    top: 25%;
    /* border-radius: 314.5px; */
    background: #c2ea2b;
    filter: blur(167.66195678710938px);
    width: 193.859px;
    height: 115.268px;
  }

  .textContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 398px;
  }

  .logoContainer {
    width: 50.7px;
    height: 60.199px;
    margin-bottom: 17px;
  }

  .logoContainer > img {
    width: 100%;
    height: 100%;
  }
  .mainHeading {
    color: #fff;
    text-align: center;
    font-family: TelegraphLight;
    font-size: 52px;
    font-style: normal;
    font-weight: 200;
    line-height: 100%; /* 52px */
    text-transform: uppercase;
    margin-bottom: 20px;
  }

  .subHeading {
    color: #fff;
    text-align: center;
    font-family: TelegraphReg;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px; /* 177.778% */
    text-transform: capitalize;
    margin-bottom: 24px;
  }
}
@media (max-width: 580px) {
  .mainHeading {
    font-size: 40px;
  }
}
@media (max-width: 480px) {
  .mainContainer {
    display: flex;
    justify-content: center;
    /* height: 900px; */
    margin-top: -120px;
    /* margin-top: -23.256vw; */
    padding-bottom: 27.907vw;
    overflow: hidden;
  }

  .subContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding-left: 16px;
    padding-right: 16px;
  }

  .mainHeading {
    color: #fff;
    text-align: center;
    font-family: TelegraphLight;
    font-size: 40px;
    font-style: normal;
    font-weight: 200;
    line-height: 100%; /* 52px */
    text-transform: uppercase;
    margin-bottom: 17px;
  }

  .subHeading {
    color: #fff;
    text-align: center;
    font-family: TelegraphReg;
    font-size: 4.186vw;
    font-style: normal;
    font-weight: 400;
    line-height: 7.442vw; /* 177.778% */
    text-transform: capitalize;
  }

  .textContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 92.558vw;
  }

  .logoContainer {
    width: 11.791vw;
    height: 14vw;
    margin-bottom: 3.953vw;
  }
  .GradientL {
    /* display: none; */
    position: absolute;
    left: -69.767vw;
    bottom: 35%;
    width: 45.083vw;
    height: 26.807vw;
    /* border-radius: 30%; */
    background: #caff00;
    filter: blur(167.66195678710938px);
  }

  .GradientR {
    /* display: none; */
    position: absolute;
    right: -69.767vw;
    top: 25%;
    /* border-radius: 314.5px; */
    background: #c2ea2b;
    filter: blur(167.66195678710938px);
    width: 45.083vw;
    height: 26.807vw;
  }
}
