body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0;
}

img {
  pointer-events: none;
}
html {
  --ytd-scrollbar-width: 8px;
  scrollbar-color: #aaa transparent;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
@font-face {
  font-family: TelegraphReg;
  /* font-weight: bold; */
  src: url("../public/PPTelegraf/PPTelegraf-Regular.otf") format("opentype");
}

@font-face {
  font-family: TelegraphBold;
  /* font-weight: bold; */
  src: url("../public/PPTelegraf/PPTelegraf-UltraBold.otf") format("opentype");
}

@font-face {
  font-family: TelegraphLight;
  /* font-weight: bold; */
  src: url("../public/PPTelegraf/PPTelegraf-UltraLight.otf") format("opentype");
}

.chakra-modal__content-container {
  outline: none;
}
.css-coekd9:focus-visible,.css-coekd9[data-focus-visible] {
  box-shadow: none !important;
}
