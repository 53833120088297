.mainContainer {
  width: 80%;
  position: relative;
  margin: 0 auto;
  margin-top: 50px;
}
.navContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}


.logo {

  animation-duration: 10s;
  animation-iteration-count: infinite;
  animation-name: zoomInOut;
  animation-timing-function: cubic-bezier(1, 0.01, 0, 1.04);
  margin-top: 30px;
  transition: transform 2s cubic-bezier(0.96, 0.02, 0.12, 1);
}

@keyframes zoomInOut {
  0%,
  100% {
    transform: scale(0.8); /* Start and end at normal size */
  }
  50% {
    transform: scale(1); /* Midway through the animation, zoom in */
  }
}

.logo:hover {
  animation-play-state: paused; /* Pause animation on hover */
}





.logoImg {
  width: 50.7px;
  height: 60.199px;
}
.links {
  display: flex;
  gap: 30px;
}
.link {
  color: #fff;
  text-align: center;
  font-family: TelegraphReg;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.72px;
  cursor: pointer;
}
.button {
  font-family: TelegraphReg;
  display: flex;
  width: 204px;
  padding: 20px 70px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 76px;
  background: #c2ea2b;
  border: 1px solid #c2ea2b;
  box-sizing: border-box;
  color: #050a02;
  font-family: TelegraphBold;
  font-size: 16px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  letter-spacing: 0.64px;
  text-decoration: none;
  transition: all 0.3s ease;
}
.button:hover {
  transform: translateY(-5px);
  filter: brightness(90%);
  transition: all 0.4s ease-in-out;
}

.mobNavContainer {
  display: none;
}
.mobMenuContainer {
  padding-top: 27px;
  display: flex;

  flex-direction: column;
  align-items: center;
  position: fixed;
  top: 0;
  right: 0;
  background-image: url('/public/images/heroBGM.svg');
  background-repeat: no-repeat;
  background-position: top center;
  background-size: 100% 1023px;
  background-color: #050a02;
  width: 100vw;
  height: 100vh;
  z-index: 100;
}
.mobHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
}


.mobLogo {

  animation-duration: 10s;
  animation-iteration-count: infinite;
  animation-name: zoomInOut;
  animation-timing-function: cubic-bezier(1, 0.01, 0, 1.04);
  margin-top: 30px;
  transition: transform 2s cubic-bezier(0.96, 0.02, 0.12, 1);
}

@keyframes zoomInOut {
  0%,
  100% {
    transform: scale(0.8); /* Start and end at normal size */
  }
  50% {
    transform: scale(1); /* Midway through the animation, zoom in */
  }
}

.mobLogo:hover {
  animation-play-state: paused; /* Pause animation on hover */
}


.moblogoImg {
  width: 33.3px;
  height: 39px;
}
.mobLinks {
  margin-top: 67px;
  display: flex;
  flex-direction: column;
}
.mobLink {
  color: #fff;
  margin-bottom: 34px;
  text-align: center;
  font-family: TelegraphLight;
  font-size: 52.185px;
  font-style: normal;
  font-weight: 200;
  line-height: 47.441px; /* 90.909% */
}
.GradientL {
  position: absolute;
  left: 0;
  bottom: 35%;
  width: 200px;
  height: 187px;
  /* border-radius: 30%; */
  background: #caff00;
  filter: blur(271.9999694824219px);
}

.GradientR {
  position: absolute;
  right: 0;
  bottom: 35%;
  /* border-radius: 314.5px; */
  background: #c2ea2b;
  filter: blur(271.9999694824219px);
  width: 200px;
  height: 187px;
}

@media (max-width: 1100px) {
  .mainContainer {
    width: 90%;
    padding-top: 27px;
    margin-top: 0;
  }
  .mobNavContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 55px;
  }
  .mobHeader {
    width: 90%;
  }
  .navContainer {
    display: none;
  }
}
