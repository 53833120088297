.mainContainer {
  display: flex;
  justify-content: center;
  position: relative;
  background-image: url("/public/images/Vector.png");
  background-repeat: no-repeat;
  background-position: top center;
}

.subContainer {
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  max-width: 1220px;
  margin-top: 185px;
}
.GradientL {
  position: absolute;
  left: -12%;
  width: 314.5px;
  height: 187px;
  flex-shrink: 0;
  border-radius: 314.5px;
  background: #caff00;

  filter: blur(271.9999694824219px);
}

.GradientR {
  position: absolute;
  right: 0;
  bottom: 35%;
  /* border-radius: 314.5px; */
  background: #c2ea2b;
  filter: blur(271.9999694824219px);
  width: 200px;
  height: 187px;
}
.heading h1 {
  color: #fff;
  text-align: center;
  font-family: TelegraphLight;
  font-size: 133px;
  font-style: normal;
  font-weight: 200;
  line-height: 100%; /* 133.714px */
  text-transform: uppercase;
}
.heading p {
  margin-top: 31px;
  color: #fff;
  text-align: center;
  font-family: TelegraphReg;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 38px; /* 158.333% */
  text-transform: capitalize;
  margin-bottom: 60px;
}

.buttonContainer {
  display: flex;
  /* width: 85%; */
  justify-content: space-between;
  column-gap: 60px;
  /* margin-top: 15px; */
  margin-bottom: 157px;
}

.button1 {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #050a02;
  font-family: TelegraphBold;
  font-size: 16px;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.64px;
  width: 447px;
  height: 62px;
  border-radius: 76px;
  background-color: #c2ea2b;
  text-decoration: none;
  transition: all 0.3s ease;
  z-index: 2;
}

.button1:hover {
  transform: translateY(-5px);
  filter: brightness(90%);
  transition: all 0.4s ease-in-out;
}

.button2 {
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #050a02;
  font-family: TelegraphBold;
  font-size: 16px;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.64px;
  width: 447px;
  height: 62px;
  border-radius: 76px;
  text-decoration: none;
  transition: all 0.3s ease;
}
.button2:hover {
  transform: translateY(-5px);
  filter: brightness(80%);
  transition: all 0.4s ease-in-out;
}

.LogoContainer {
  animation-duration: 10s;
  animation-iteration-count: infinite;
  animation-name: zoomInOut;
  animation-timing-function: cubic-bezier(1, 0.01, 0, 1.04);
  margin-bottom: 33px;
  transition: transform 2s cubic-bezier(0.96, 0.02, 0.12, 1);
}

@keyframes zoomInOut {
  0%,
  100% {
    transform: scale(0.8); /* Start and end at normal size */
  }
  50% {
    transform: scale(1); /* Midway through the animation, zoom in */
  }
}

.LogoContainer:hover {
  animation-play-state: paused; /* Pause animation on hover */
}

.linkContainer {
  display: flex;
  column-gap: 32px;
  margin-bottom: 36px;
}

.linkItem {
  color: #fff;
  font-family: TelegraphReg;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
  text-decoration: none;
}

.contractAddress {
  margin-bottom: 33px;
  color: #fff;
  font-family: TelegraphReg;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  word-break: break-all;
  text-align: center;
}
.copyright {
  margin-bottom: 60px;
  color: #fff;
  opacity: 0.5;
  font-family: TelegraphReg;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

@media (max-width: 1600px) {
  .animationContainer {
    top: 68px;
    left: -140px;
    /* -webkit-mask-position-x: fixed; */
    /* :180px ; */
    width: 20px;
    height: 55px;
    position: absolute;
    opacity: 0.7 !important;
  }
  .animation {
    /* transform: skewY(20deg); */
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: -8px;
    overflow: hidden;
    animation: scanner-loop 2s linear forwards;

    clip-path: polygon(
      0% 0%,
      0% 100%,
      /* calc(0% + 8px) 100%,
      calc(0% + 8px) calc(0% + 8px),
      calc(100% - 8px) calc(0% + 8px),
      calc(100% - 8px) calc(100% - 8px),
      calc(0% + 8px) calc(100% - 8px), */
        0% 100%,
      100% 100%,
      100% 0%
    );

    clip-path: polygon(0 17%, 100% 0, 100% 100%, 0 87%);
    clip-path: polygon(0 11%, 100% 0, 100% 100%, 0 86%);
    clip-path: polygon(19% 11%, 100% 0, 100% 100%, 19% 86%);
  }
}

@media (max-width: 1500px) {
  /* .centerText {
    color: #fff;
    text-align: center;
    font-family: TelegraphLight;
    font-size: 113.714px;
    font-style: normal;
    font-weight: 200;
    line-height: 100%; 
    text-transform: uppercase;
    max-width: 900px;
  } */

  .heading h1 {
    font-size: 90px;
  }

  .heading p {
    font-size: 24px;
  }
  .buttonContainer {
    display: flex;
    width: 85%;
    justify-content: center;
    column-gap: 30px;
    /* margin-top: 15px; */
  }

  .button1 {
    /* width: 100%; */
    display: flex;
    justify-content: center;
    align-items: center;
    /* margin-top: 50px; */
    color: #050a02;

    font-family: TelegraphBold;
    font-size: 16px;
    font-style: normal;
    /* font-weight: 800; */
    line-height: normal;
    letter-spacing: 0.64px;
    width: 390px;
    height: 62px;
    border-radius: 76px;
    background-color: #c2ea2b;
  }

  .button2 {
    /* width: 100%; */
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    /* margin-top: 50px; */
    color: #050a02;

    font-family: TelegraphBold;
    font-size: 16px;
    font-style: normal;
    /* font-weight: 800; */
    line-height: normal;
    letter-spacing: 0.64px;
    width: 390px;
    height: 62px;
    border-radius: 76px;
    /* background-color: #c2ea2b; */
  }
  .animationContainer {
    top: 66px;
    left: -77px;
    /* -webkit-mask-position-x: fixed; */
    /* :180px ; */
    width: 20px;
    height: 45px;
    position: absolute;
    opacity: 0.7 !important;
  }
  .animation {
    /* transform: skewY(20deg); */
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: -7px;
    padding: 4px;
    overflow: hidden;
    animation: scanner-loop 2s linear forwards;

    clip-path: polygon(
      0% 0%,
      0% 100%,
      /* calc(0% + 8px) 100%,
      calc(0% + 8px) calc(0% + 8px),
      calc(100% - 8px) calc(0% + 8px),
      calc(100% - 8px) calc(100% - 8px),
      calc(0% + 8px) calc(100% - 8px), */
        0% 100%,
      100% 100%,
      100% 0%
    );

    clip-path: polygon(0 17%, 100% 0, 100% 100%, 0 87%);
    clip-path: polygon(0 11%, 100% 0, 100% 100%, 0 86%);
    clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 0);
  }
  .light {
    filter: blur(40px);
    width: 100px;
    height: 50px;
    top: 50%;
    left: 50%;
    border-radius: 50%;
    margin: -335px 0 0 -70px;
    border: 300px solid transparent;
    border-right: none;
    /* border-left: 200px solid rgb(180, 180, 180); */
    opacity: 0.3 !important;
    position: absolute;
    animation: lightAnimation 2s linear forwards;

    transform: rotate(180deg);
  }
  @keyframes lightAnimation {
    0% {
      width: 0px;
      top: 0;
      opacity: 0;
      border-left: 0px;
    }
    10% {
      width: 5px;
    }
    20% {
      width: 10px;
    }
    30% {
      width: 20px;
    }
    40% {
      width: 25px;
    }
    50% {
      width: 30px;
    }
    60% {
      width: 35px;
    }
    70% {
      width: 40px;
      border-left: 0px;
    }
    100% {
      opacity: 0.3;
      border-left: 900px solid rgb(180, 180, 180);
    }
  }
  @keyframes scanner-loop {
    0% {
      top: 0;
      width: 10px;
    }

    30% {
      top: 0;
      width: 10px;
    }
    100% {
      top: 0;
      width: 68px;
    }
  }
}

@media (max-width: 1180px) {
  /* .centerText {
    color: #fff;
    text-align: center;
    font-family: TelegraphLight;
    font-size: 93.714px;
    font-style: normal;
    font-weight: 200;
    line-height: 100%; 
    text-transform: uppercase;
    max-width: 750px;
  } */
  .heading h1 {
    font-size: 70px;
  }
  .heading p {
    font-size: 22px;
  }

  .button1 {
    /* width: 100%; */
    display: flex;
    justify-content: center;
    align-items: center;
    /* margin-top: 50px; */
    color: #050a02;

    font-family: TelegraphBold;
    font-size: 16px;
    font-style: normal;
    /* font-weight: 800; */
    line-height: normal;
    letter-spacing: 0.64px;
    width: 330px;
    height: 62px;
    border-radius: 76px;
    background-color: #c2ea2b;
  }

  .button2 {
    /* width: 100%; */
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    /* margin-top: 50px; */
    color: #050a02;

    font-family: TelegraphBold;
    font-size: 16px;
    font-style: normal;
    /* font-weight: 800; */
    line-height: normal;
    letter-spacing: 0.64px;
    width: 330px;
    height: 62px;
    border-radius: 76px;
    /* background-color: #c2ea2b; */
  }
}

@media (max-width: 1100px) {
  .animationContainer {
    display: none;
    top: 88px;
    left: -83px;
    /* -webkit-mask-position-x: fixed; */
    /* :180px ; */
    width: 20px;
    height: 45px;
    position: absolute;
    opacity: 0.7;
  }
}
@media (max-width: 780px) {
  .mainContainer {
    display: flex;
    justify-content: center;
    position: relative;
  }

  .subContainer {
    width: 93%;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
    max-width: 1150px;
    margin-top: 180px;
  }

  .textContainer {
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
    margin-top: 0px;
    /* max-width: 1150px; */
  }

  /* .centerText {
    color: #fff;
    text-align: center;
    font-family: TelegraphLight;
    font-size: 92px;
    font-style: normal;
    font-weight: 200;
    line-height: 78px;
    text-transform: unset;
    max-width: 450px;
  } */

  .heading h1 {
    font-size: 52px;
    font-family: TelegraphReg;
  }
  .heading p {
    font-size: 18px;
    font-family: TelegraphReg;
    margin-top: 17px;
    margin-bottom: 32px;
  }

  .buttonContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    row-gap: 32px;
    margin-bottom: 137px;
  }

  .button1 {
    /* width: 100%; */
    display: flex;
    justify-content: center;
    align-items: center;
    /* margin-top: 50px; */
    color: #050a02;

    font-family: TelegraphBold;
    font-size: 16px;
    font-style: normal;
    /* font-weight: 800; */
    line-height: normal;
    letter-spacing: 0.64px;
    width: 100%;
    max-width: 398px;

    height: 62px;
    border-radius: 76px;
    background-color: #c2ea2b;
  }

  .button2 {
    /* width: 100%; */
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    /* margin-top: 50px; */
    color: #050a02;

    font-family: TelegraphBold;
    font-size: 16px;
    font-style: normal;
    /* font-weight: 800; */
    line-height: normal;
    letter-spacing: 0.64px;
    width: 100%;
    max-width: 398px;
    height: 62px;
    border-radius: 76px;
    /* background-color: #c2ea2b; */
  }

  .LogoContainer {
    margin-bottom: 40px;
  }

  .LogoContainer > img {
    width: 39px;
    height: 46px;
  }

  .linkContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    row-gap: 32px;
    margin-bottom: 33px;
    width: 100%;
  }

  .linkItem {
    color: #fff;

    font-family: TelegraphReg;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .copyright {
    margin-bottom: 38px;
    color: #fff;
    opacity: 0.5;
    font-family: TelegraphReg;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .contractAddress {
    margin-bottom: 30px;
  }
}

@media (max-width: 480px) {
  .mainContainer {
    display: flex;
    justify-content: center;
    position: relative;

    /* background: url("../../public/images/heroBg.svg"); */

    /* background: url("../../public/images/heroBg.svg");
    height: 100%;
    width: 100%;
    min-height: 100vh;
    background-position: top center;
    background-repeat: no-repeat; */
    /* background-size:cover; */

    /* height: 900px; */

    background-size: 904px 743px;
  }

  .subContainer {
    width: 93%;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
    max-width: 1150px;
    margin-top: 180px;
  }

  .heading p {
    color: #fff;

    text-align: center;
    font-family: TelegraphReg;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px; /* 177.778% */
    text-transform: capitalize;
  }

  .buttonContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    row-gap: 32px;
    margin-bottom: 108px;
  }

  .button1 {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #050a02;

    font-family: TelegraphBold;
    font-style: normal;
    line-height: normal;

    border-radius: 76px;
    background-color: #c2ea2b;
  }

  .button2 {
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #050a02;

    font-family: TelegraphBold;

    border-radius: 76px;
  }

  .LogoContainer {
    margin-bottom: 9.302vw;
  }

  .LogoContainer > img {
    width: 39px;
    height: 46px;
  }

  .linkContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    row-gap: 7.442vw;
    margin-bottom: 7.674vw;
    width: 100%;
  }

  .linkItem {
    color: #fff;

    font-family: TelegraphReg;
    font-size: 3.256vw;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .copyright {
    margin-bottom: 8.837vw;
    color: #fff;
    opacity: 0.5;
    font-family: TelegraphReg;
    font-size: 3.256vw;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .contractAddress {
    margin-bottom: 30px;
    font-size: 12px;
  }
}

@media (min-width: 1920px) {
  .animationContainer {
    display: none;
    top: 60px;
    left: -188px;
    width: 50px;
    height: 60px;
    position: absolute;
    opacity: 0.7 !important;
  }
}
