.swiper-slide {
    /* text-align: center; */
    /* font-size: 18px;
    display: flex;
    justify-content: center; */
    /* align-items: center; */
    width: 100%  !important;
  }
  
  .swiper {
    padding: 0px 0px 60px 0px;
  }
  
  .swiper-pagination-bullet {
    width: 18px;
    height: 18px;
    border-radius: 3px;
    border: 1px solid #c2ea2b;
    background: rgba(194, 234, 43, 0);
    opacity: 1;
    margin: 0px 10px !important;
  }
  
  .swiper-pagination-bullet-active {
    background: #c2ea2b;
  }
  