.container {
  background-color: #000;
  min-height: 100vh;
}
.heroSection {
  margin-top: 100px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding-left: 10%;
  padding-right: 2%;
  align-items: center;
  gap: 2rem;
}

.leftSide {
  display: flex;
  flex-direction: column;
  gap: 2.188rem;
}

/* .headingWrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
} */

.mainHeading {
  max-width: 715px;
  color: #fff;
  font-family: TelegraphLight;
  font-size: 90px;
  font-style: light;
  line-height: 84.632px;
}
.greenUnder {
  color: #ccff00;
}

.logo {
  display: inline-flex;
  align-items: center;
  padding: 0 10px;
  animation-duration: 10s;
  animation-iteration-count: infinite;
  animation-name: zoomInOut;
  animation-timing-function: cubic-bezier(1, 0.01, 0, 1.04);
  margin-top: 30px;
  transition: transform 2s cubic-bezier(0.96, 0.02, 0.12, 1);
}

@keyframes zoomInOut {
  0%,
  100% {
    transform: scale(0.8); /* Start and end at normal size */
  }
  50% {
    transform: scale(1); /* Midway through the animation, zoom in */
  }
}

.logo:hover {
  animation-play-state: paused; /* Pause animation on hover */
}

.logo img {
  height: 4rem;
}

.subHeading {
  margin-top: 28px;
  max-width: 701px;
  color: #fff;
  font-family: TelegraphReg;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 38px; /* 158.333% */
  text-transform: capitalize;
}

.button {
  display: flex;
  max-width: 240px;
  width: 100%;
  padding: 20px 55px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 58px;
  background: #c2ea2b;
  border: 1px solid #c2ea2b;
  box-sizing: border-box;
  color: #050a02;
  font-family: TelegraphBold;
  font-size: 16px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  letter-spacing: 0.64px;
  text-decoration: none;
  transition: all 0.3s ease;
}

.buttonM {
  display: none;
}
.button:hover {
  transform: translateY(-5px);
  filter: brightness(90%);
  transition: all 0.4s ease-in-out;
}
.sliderCont {
  display: none;
}
.statsContainerBorder {
  background: linear-gradient(to right, #353a33 0%, #121410 100%);
  padding: 1.32px;
  border-radius: 40px;
  max-width: 710px;
  overflow: hidden;
}
.statsContainer {
  background-color: #0a0c0a;
  border-radius: 40px;
  max-width: 710px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 31px 60px;
  column-gap: 25px;
}
.spacer {
  background: linear-gradient(270deg, rgba(53, 58, 51, 0) 0%, #353a33 100%);
  min-width: 1.32px;
  height: 75px;
}
.stats h1 {
  color: #fff;
  font-family: TelegraphReg;
  font-size: 60px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 65px */
  text-transform: uppercase;
}
.stats h1 span {
  font-size: 45px;
}
.stats p {
  color: #fff;
  font-family: TelegraphReg;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 38px; /* 158.333% */
  text-transform: capitalize;
}

.slider {
  width: 350px;
}
.sliderBorder {
  width: 310px;
  height: 132px;
  max-width: 310px;
  max-height: 132px;
  flex-shrink: 0;
  border-radius: 30px;
  background: linear-gradient(to right, #353a33 0%, #121410 100%);
  padding: 1.32px;
  overflow: hidden;
}
.smallSlider {
  background: #0a0c0a;
  min-height: 100% !important;
  min-width: 100% !important;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.smallSlider h1 {
  color: #fff;

  text-align: center;
  font-family: TelegraphReg;
  font-size: 60px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 60px */
  text-transform: uppercase;
}
.smallSlider p {
  color: #fff;

  text-align: center;
  font-family: TelegraphReg;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 38px; /* 190% */
  text-transform: capitalize;
}

.rightSide {
  align-self: flex-start;
  display: flex;
  justify-content: center;
}

.dashboardWrapper {
  position: relative;
  width: 46.615vw;

  /* padding: 1rem; */
}

.dashboardWrapperM {
  position: relative;
  display: none;
}

.dashboardImage {
  width: 100%;
  height: auto;
  border-radius: 0.5rem;
}

.videoOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  pointer-events: none;
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
}

.videoOverlay.hidden {
  opacity: 0;
}

.dashboardWrapper video {
  width: 100%;
  height: 100%;
  border-radius: 25px;
}

.dashboardWrapperM video {
  width: 100%;
  height: 100%;
  border-radius: 25px;
}

@media (max-width: 1800px) {
  .mainHeading {
    font-size: 80px;
  }

  .statsContainer {
    padding: 22px 40px;
    column-gap: 20px;
  }
  .stats h1 {
    font-size: 45px;
  }
  .stats h1 span {
    font-size: 30px;
  }
  .stats p {
    font-size: 22px;

    line-height: 38px;
  }
}
@media (max-width: 1650px) {
  .leftSide {
    gap: 2rem;
  }
  .mainHeading {
    font-size: 70px;
  }
  /* .dashboardWrapper {
    padding: 1rem !important;
    width: 60vw !important;
  } */

  .subHeading {
    font-size: 22px;
    margin-top: 24px;
  }
  .statsContainerBorder {
    border-radius: 25px;
  }
  .statsContainer {
    padding: 16px 30px;
    column-gap: 15px;
    border-radius: 25px;
  }
  .stats h1 {
    font-size: 35px;
  }
  .stats h1 span {
    font-size: 25px;
  }
  .stats p {
    font-size: 18px;

    line-height: 30px;
  }
}
@media (max-width: 1420px) {
  .heroSection {
    /* padding-left: 8%; */
    padding-right: 2%;
  }
  .leftSide {
    gap: 1.5rem;
  }

  .logo img {
    height: 3.5rem;
  }
  .mainHeading {
    font-size: 60px;
  }
  .subHeading {
    margin-top: 20px;
    font-size: 22px;
    line-height: 30px;
  }
  .statsContainer {
    padding: 16px 30px;
    column-gap: 15px;
  }
  .stats h1 {
    font-size: 35px;
  }
  .stats h1 span {
    font-size: 25px;
  }
  .stats p {
    font-size: 18px;

    line-height: 30px;
  }
}

@media (max-width: 1280px) {
  .heroSection {
    padding-left: 5%;
    padding-right: 5%;
    gap: 0rem;
  }
  .mainHeading {
    font-size: 90px;
  }
  .heroSection {
    grid-template-columns: 1fr;
  }
  .leftSide {
    align-items: center;
  }
  .subHeading {
    text-align: center;
  }
  .dashboardWrapper {
    display: none;
  }

  .dashboardWrapperM {
    display: flex;

    width: 100%; /* This ensures the container takes full width on all devices */
    max-width: 100%; /* Prevents overflow on smaller screens */
 
    overflow: hidden;
  }
}
@media (max-width: 1100px) {
  .sliderCont {
    display: flex;
    justify-content: center;
    width: 100%;
  }
  .statsContainerBorder {
    display: none;
  }
  .heroSection {
    max-width: 90%;
    padding-left: 0;
    padding-right: 0;
    margin: 0 auto;
  }
}
@media (max-width: 1024px) {
  .heroSection {
    grid-template-columns: 1fr;
    /* gap: 4rem; */
  }
  .logo {
    margin-top: 15px;
  }

  .rightSide {
    order: -1;
  }

  .mainHeading {
    font-size: 70px;
    line-height: 70px;
  }
}

@media (max-width: 768px) {
  .mainHeading {
    font-size: 60px;
  }

  .button {
    display: none;
  }

  .buttonM {
    display: flex;
    max-width: 240px;
    width: 100%;
    padding: 20px 55px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 58px;
    background: #c2ea2b;
    border: 1px solid #c2ea2b;
    box-sizing: border-box;
    color: #050a02;
    font-family: TelegraphBold;
    font-size: 16px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    letter-spacing: 0.64px;
    text-decoration: none;
    transition: all 0.3s ease;
  }
}
@media (max-width: 540px) {
  .logo {
    padding: 0px;
    margin-top: 0px;
  }
  .logo img {
    width: 32px;
    height: 38px;
  }
  .mainHeading {
    font-size: 50px;
    text-align: center;
  }
  .subHeading {
    font-size: 18px;
  }

  .buttonM {
    max-width: 90%;
  }

  .heroSection {
    max-width: 100%;
  }
  .dashboardWrapperM {
    max-width: 90%;
  }
}

@media (max-width: 415px) {
  .mainHeading {
    font-size: 45px;
    text-align: center;
  }
  .subHeading {
    font-size: 18px;
  }
  .headingWrapper {
    width: 95%;
  }
  .buttonM {
    max-width: 90%;
  }
}
