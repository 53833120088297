.mainContainer {
  position: relative;
  display: flex;
  justify-content: center;
  /* height: 900px; */
  padding-bottom: 40px;
  /* margin-top: 253px; */
  background: url("../../public/images/networkBg1.png");
  background-repeat: no-repeat;
  background-position: top left;
  background-size: 97% 824px;
  height: 900px;
}
.badge {
  /* width: 400px; */
  left: 0px;
  bottom: -13%;
  position: absolute;
  mix-blend-mode: luminosity;
}

.badgeM {
  display: none;
}
.subContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
  max-width: 1417px;
  position: relative;
}

.headingContainer {
  top: 20%;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* align-items: flex-start; */
}

.mainHeading {
  /* padding-top: 200px; */
  color: #050a02;

  text-align: center;
  font-family: TelegraphReg;
  font-size: 144.84px;
  font-style: normal;
  font-weight: 400;
  line-height: 144.84px; /* 100% */
  text-transform: uppercase;
  margin-bottom: 44px;
}

.bottomContainer {
  display: flex;
  justify-content: center;
  column-gap: 90px;
  width: 90%;
}

.Cta {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  color: #050a02;
  font-family: TelegraphReg;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 56px;
  text-transform: uppercase;
}

.desc {
  color: #050a02;
  font-family: TelegraphReg;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px; /* 187.5% */
  margin-bottom: 20px;
}

.button1 {
  display: flex;
  width: 100%;
  height: 62px;
  justify-content: center;
  align-items: center;
  /* gap: 10px; */
  border-radius: 58px;
  background: #c2ea2b;
  border: 1px solid #c2ea2b;

  box-sizing: border-box;

  color: #050a02;
  font-family: TelegraphBold;
  font-size: 16px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  letter-spacing: 0.64px;
  text-decoration: none;
  transition: all 0.3s ease;
}
.button1:hover {
  transform: translateY(-5px);
  filter: brightness(90%);
  transition: all 0.4s ease-in-out;
}
.button2 {
  display: flex;
  width: 100%;
  height: 62px;
  justify-content: center;
  align-items: center;
  /* gap: 10px; */
  border-radius: 58px;

  border: 1px solid #050a02;
  box-sizing: border-box;

  color: #050a02;
  font-family: TelegraphBold;
  font-size: 16px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  letter-spacing: 0.64px;
  text-decoration: none;
  transition: all 0.3s ease;
}
.button2:hover {
  transform: translateY(-5px);
  transition: all 0.4s ease-in-out;
  background-color: rgba(0, 0, 0, 0.1);
}
.sliderCont {
  display: none;
}
.stats {
  margin-top: 5%;
  max-width: 805px;
  width: 100%;
}
.statsContainerBorder {
  background: linear-gradient(to right, #353a33 0%, #121410 100%);
  padding: 1.32px;
  border-radius: 40px;

  overflow: hidden;
}
.statsContainer {
  background-color: #0a0c0a;
  border-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px 40px;
  max-width: 805px;
}
.spacer {
  background: linear-gradient(270deg, rgba(53, 58, 51, 0) 0%, #353a33 100%);
  min-width: 1.32px;
  height: 75px;
}
.statss h1 {
  color: #fff;

  font-family: TelegraphReg;
  font-size: 65px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 65px */
}
.span {
  font-size: 55px;
}
.spanY {
  font-size: 40px;
  margin-left: 5px;
}
.statss p {
  color: #fff;
  font-family: TelegraphReg;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 38px; /* 158.333% */
  text-align: center;
  margin-top: -10px;
}
@media (max-width: 1800px) {
  .mainContainer {
    display: flex;
    justify-content: center;
    /* height: 900px; */
    padding-bottom: 40px;
    /* margin-top: 253px; */
    background: url("../../public/images/networkBg1.png");
    background-repeat: no-repeat;
    background-position: top center;
    background-size: 1800px;
    height: 900px;
    /* background-size: 95%; */

    /* background-size: 95% 821px; */
  }

  .stats {
    margin-top: 3%;
    max-width: 805px;
    width: 100%;
  }
  .badge {
    width: 350px;
    bottom: -7%;
  }

  .statsContainerBorder {
    background: linear-gradient(to right, #353a33 0%, #121410 100%);
    padding: 1.32px;
    border-radius: 40px;

    overflow: hidden;
  }

  .spacer {
    background: linear-gradient(270deg, rgba(53, 58, 51, 0) 0%, #353a33 100%);
    min-width: 1.32px;
    height: 75px;
  }
}

@media (max-width: 1580px) {
  .badge {
    width: 300px;
  }
}

@media (max-width: 1480px) {
  .badge {
    left: 0px;
    position: absolute;
    mix-blend-mode: luminosity;
    width: 250px;
  }

  .badgeM {
    display: none;
  }
  .subContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%;
    max-width: 1417px;
    position: relative;
  }

  .headingContainer {
    top: 22%;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 1050px;
    /* align-items: flex-start; */
  }

  .mainHeading {
    /* padding-top: 200px; */
    color: #050a02;

    text-align: center;
    font-family: TelegraphReg;
    font-size: 114.84px;
    font-style: normal;
    font-weight: 400;
    line-height: 114.84px; /* 100% */
    text-transform: uppercase;
    margin-bottom: 44px;
  }

  .bottomContainer {
    display: flex;
    justify-content: center;
    column-gap: 40px;
    width: 90%;
  }

  .Cta {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .title {
    color: #050a02;
    font-family: TelegraphReg;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 56px; /* 200% */
    text-transform: uppercase;
  }

  .desc {
    text-align: center;
    color: #050a02;
    font-family: TelegraphReg;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px; /* 187.5% */
    margin-bottom: 20px;
    width: 90%;
  }

  .button1 {
    display: flex;
    width: 100%;
    height: 62px;
    justify-content: center;
    align-items: center;
    /* gap: 10px; */
    border-radius: 58px;
    background: #c2ea2b;
    border: 1px solid #c2ea2b;

    box-sizing: border-box;

    color: #050a02;
    font-family: TelegraphBold;
    font-size: 16px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    letter-spacing: 0.64px;
  }

  .button2 {
    display: flex;
    width: 100%;
    height: 62px;
    justify-content: center;
    align-items: center;
    /* gap: 10px; */
    border-radius: 58px;

    border: 1px solid #050a02;
    box-sizing: border-box;

    color: #050a02;
    font-family: TelegraphBold;
    font-size: 16px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    letter-spacing: 0.64px;
  }
}

@media (max-width: 1280px) {
  .badge {
    top: 0px;
  }
}

@media (max-width: 1100px) {
  .stats {
    display: none;
  }
  .sliderCont {
    margin-top: 7%;
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .slider {
    width: 350px;
  }
  .sliderBorder {
    width: 310px;
    height: 132px;
    max-width: 310px;
    max-height: 132px;
    flex-shrink: 0;
    border-radius: 30px;
    background: linear-gradient(to right, #353a33 0%, #121410 100%);
    padding: 1.32px;
    overflow: hidden;
  }
  .smallSlider {
    background: #0a0c0a;
    min-height: 100% !important;
    min-width: 100% !important;
    border-radius: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .smallSlider h1 {
    color: #fff;

    text-align: center;
    font-family: TelegraphReg;
    font-size: 60px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 60px */
  }
  .smallSlider p {
    color: #fff;
    text-align: center;
    font-family: TelegraphReg;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 38px; /* 190% */
    text-transform: capitalize;
  }
}
@media (max-width: 1024px) {
  .badge {
    top: 20px;
    left: 0px;
    position: absolute;
    mix-blend-mode: luminosity;
    width: 200px;
  }

  .badgeM {
    display: none;
  }
  .subContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%;
    max-width: 1417px;
    position: relative;
  }

  .headingContainer {
    top: 22%;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 1250px;
    /* align-items: flex-start; */
  }

  .mainHeading {
    /* padding-top: 200px; */
    color: #050a02;

    text-align: center;
    font-family: TelegraphReg;
    font-size: 94.84px;
    font-style: normal;
    font-weight: 400;
    line-height: 94.84px; /* 100% */
    text-transform: uppercase;
    margin-bottom: 44px;
  }

  .bottomContainer {
    display: flex;
    justify-content: center;
    column-gap: 30px;
    width: 90%;
  }

  .Cta {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .title {
    color: #050a02;
    font-family: TelegraphReg;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 56px; /* 200% */
    text-transform: uppercase;
  }

  .desc {
    text-align: center;
    color: #050a02;
    font-family: TelegraphReg;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px; /* 187.5% */
    margin-bottom: 20px;
    width: 90%;
  }

  .button1 {
    display: flex;
    max-width: 340px;
    width: 100%;
    height: 62px;
    justify-content: center;
    align-items: center;
    /* gap: 10px; */
    border-radius: 58px;
    background: #c2ea2b;
    border: 1px solid #c2ea2b;

    box-sizing: border-box;

    color: #050a02;
    font-family: TelegraphBold;
    font-size: 16px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    letter-spacing: 0.64px;
  }

  .button2 {
    display: flex;
    max-width: 340px;

    width: 100%;
    height: 62px;
    justify-content: center;
    align-items: center;
    /* gap: 10px; */
    border-radius: 58px;

    border: 1px solid #050a02;
    box-sizing: border-box;

    color: #050a02;
    font-family: TelegraphBold;
    font-size: 16px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    letter-spacing: 0.64px;
  }

  .sliderCont {
    margin-top: 110px;
  }
}
@media (max-width: 780px) {
  .mainContainer {
    display: flex;
    justify-content: center;
    /* height: 900px; */
    padding-bottom: 40px;
    /* margin-top: 253px; */
    background: url("../../public/images/networkBGM.svg");
    background-repeat: no-repeat;
    background-position: top center;
    background-size: 100% 824px;
    /* height: 824px; */
    /* background-size: 95%; */

    /* background-size: 95% 821px; */
  }
  .badge {
    display: none;
  }

  .badgeM {
    display: flex;
    left: 0px;
    top: 7%;
    position: absolute;
    mix-blend-mode: luminosity;
  }
  .subContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%;
    max-width: 1417px;
    position: relative;
  }

  .headingContainer {
    top: 21%;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* align-items: flex-start; */
  }

  .mainHeading {
    /* padding-top: 200px; */
    color: #050a02;
    max-width: 450px;
    text-align: center;
    font-family: TelegraphReg;
    font-size: 52px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 100% */
    text-transform: uppercase;
    margin-bottom: 36px;
  }

  .bottomContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    row-gap: 36px;
    width: 90%;
  }

  .Cta {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .title {
    color: #050a02;
    font-family: TelegraphReg;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 56px; /* 200% */
    text-transform: uppercase;
  }

  .desc {
    color: #050a02;
    font-family: TelegraphReg;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px; /* 187.5% */
    margin-bottom: 16px;
  }

  .button1 {
    display: flex;
    width: 100%;
    height: 62px;
    max-width: 340px;
    justify-content: center;
    align-items: center;
    /* gap: 10px; */
    border-radius: 58px;
    background: #c2ea2b;
    border: 1px solid #c2ea2b;

    box-sizing: border-box;

    color: #050a02;
    font-family: TelegraphBold;
    font-size: 16px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    letter-spacing: 0.64px;
  }

  .button2 {
    display: flex;
    width: 100%;
    height: 62px;
    max-width: 340px;

    justify-content: center;
    align-items: center;
    /* gap: 10px; */
    border-radius: 58px;

    border: 1px solid #050a02;
    box-sizing: border-box;

    color: #050a02;
    font-family: TelegraphBold;
    font-size: 16px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    letter-spacing: 0.64px;
  }

  .sliderCont {
    margin-top: 80px;
  }
}

@media (max-width: 620px) {
  .sliderCont {
    margin-top: 60px;
  }
}
@media (max-width: 480px) {
  .mainContainer {
    display: flex;
    justify-content: center;
    height: 209.302vw;
    padding-bottom: 12.902vw;
    /* margin-top: 253px; */
    background: url("../../public/images/networkBGM.svg");
    background-repeat: no-repeat;
    background-position: top center;
    background-size: 100% auto;
    /* height: 824px; */
    /* background-size: 95%; */

    /* background-size: 95% 821px; */
  }
  .badge {
    display: none;
  }

  .badgeM {
    display: flex;
    left: 0px;
    top: 7%;
    width: 19.535vw;
    height: 33.721vw;
    position: absolute;
    mix-blend-mode: luminosity;
  }
  .subContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%;
    max-width: 1417px;
    position: relative;
  }

  .headingContainer {
    top: 44.186vw;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* align-items: flex-start; */
  }

  .mainHeading {
    /* padding-top: 200px; */
    color: #050a02;

    text-align: center;
    font-family: TelegraphReg;
    font-size: 12.093vw;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 100% */
    text-transform: uppercase;
    margin-bottom: 8.372vw;
  }

  .bottomContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    row-gap: 8.372vw;
    width: 92%;
  }

  .Cta {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .title {
    color: #050a02;
    font-family: TelegraphReg;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 14.884vw; /* 200% */
    text-transform: uppercase;
  }

  .desc {
    color: #050a02;
    font-family: TelegraphReg;
    text-align: center;
    font-size: 3.721vw;
    font-style: normal;
    font-weight: 400;
    line-height: 6.977vw; /* 187.5% */
    margin-bottom: 3.721vw;
    width: 100%;
  }

  .button1 {
    display: flex;
    width: 100%;
    height: 14.419vw;
    max-width: 340px;
    justify-content: center;
    align-items: center;
    /* gap: 10px; */
    border-radius: 58px;
    background: #c2ea2b;
    border: 1px solid #c2ea2b;

    box-sizing: border-box;

    color: #050a02;
    font-family: TelegraphBold;
    font-size: 3.721vw;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    letter-spacing: 0.149vw;
  }

  .button2 {
    display: flex;
    width: 100%;
    height: 14.419vw;
    max-width: 340px;

    justify-content: center;
    align-items: center;
    /* gap: 10px; */
    border-radius: 58px;

    border: 1px solid #050a02;
    box-sizing: border-box;

    color: #050a02;
    font-family: TelegraphBold;
    font-size: 3.721vw;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    letter-spacing: 0.149vw;
  }

  .sliderCont {
    margin-top: 40px;
  }
}

@media (min-width: 1920px) {
  .mainContainer {
    display: flex;
    justify-content: center;

    background: url("../../public/images/networkBg1.png");

    background-repeat: no-repeat;
    background-position: top left;
    background-size: 97% 831px;
  }
}
