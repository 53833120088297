.mainContainer {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 200px;
}
.content {
  width: 90%;
  max-width: 1417px;
}
.GradientL {
  position: absolute;
  left: -5%;
  bottom: 35%;
  width: 314.5px;
  height: 187px;
  /* border-radius: 30%; */
  background: #caff00;
  filter: blur(271.9999694824219px);
}

.GradientR {
  position: absolute;
  right: -15%;
  top: 25%;
  /* border-radius: 314.5px; */
  background: #c2ea2b;
  filter: blur(271.9999694824219px);
  width: 314.5px;
  height: 187px;
}
.border {
  background: linear-gradient(to right, #353a33 0%, #121410 100%);
  padding: 1.3px;
  border-radius: 50px;
  width: 100%;
  /* overflow: hidden; */
}
.sideImg {
  position: absolute;
  top: -129px;
  right: -219px;
  width: 535px;
  height: 535px;
  z-index: 10;
}

.subContainer {
  /* margin-top: 150px; */
  width: 100%;
  background-color: #0a0c0a;
  /* padding: 80px 60px; */
  border-radius: 50px;
  max-width: 1440px;
  position: relative;
}
.heading h1 {
  padding-top: 83px;
  padding-left: 111px;

  color: #fff;
  font-family: TelegraphLight;
  font-size: 88px;
  font-style: normal;
  font-weight: 200;
  line-height: 100%; /* 88px */
  text-transform: uppercase;
}

.videoOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  pointer-events: none; /* Allows clicks to go through to the video */
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
}

.videContainer {
  padding: 64px 68px 68px 68px;
}
.video {
  /* margin: 64px 68px 68px 68px; */
  position: relative;
  overflow: hidden;
  width: 100%;
}
.video video {
  border-radius: 20px;
  width: 100%;
  height: 100%;
}

@media (max-width: 1800px) {
  .sideImg {
    top: -100px;
    right: -100px;
    width: 420px;
    height: 420px;
  }
}
@media (max-width: 1480px) {
  .sideImg {
    top: -120px;
    right: -50px;
    width: 350px;
    height: 350px;
  }
}
@media (max-width: 1280px) {
  .sideImg {
    top: -100px;
    right: -50px;
    width: 280px;
    height: 280px;
  }
}
@media (max-width: 1140px) {
  .heading h1 {
    font-size: 78px;
  }

  .heading h1 {
    padding-top: 83px;
    padding-left: 40px;

    color: #fff;
    font-family: TelegraphLight;
    font-size: 88px;
    font-style: normal;
    font-weight: 200;
    line-height: 100%; /* 88px */
    text-transform: uppercase;
  }
  .videContainer {
    padding: 40px;
  }
  .video {
    position: relative;
    overflow: hidden;
    width: 100%;
  }
}

@media (max-width: 1000px) {
  .heading h1 {
    font-size: 68px;
  }

  .sideImg {
    top: -70px;
    right: -50px;
    width: 220px;
    height: 220px;
  }
}
@media (max-width: 870px) {
  .mainContainer {
    margin-top: 181px;
  }
  .heading h1 {
    font-size: 55px;
    text-align: center;
    padding-top: 63px;
    padding-left: 0px;
  }
  .GradientL {
    display: none;
  }

  .GradientR {
    display: none;
  }

  .videContainer {
    padding: 30px 20px 20px 20px;
  }

  .video {
    position: relative;
    overflow: hidden;
    width: 100%;
  }
  .video video {
    border-radius: 20px;
  }
}
@media (max-width: 780px) {
  .border {
    border-radius: 30px;
    padding: 1px;
  }
  .subContainer {
    border-radius: 30px;
    /* padding: 63px 20px 20px 20px; */
  }
  .sideImg {
    top: -60px;
    right: -50px;
    width: 160px;
    height: 160px;
  }
}
@media (max-width: 740px) {
  .heading h1 {
    font-size: 40px;
  }
}

@media (max-width: 480px) {
  .mainContainer {
    margin-top: 141px;
  }
  .heading h1 {
    text-align: center;
    font-size: 37px;
    padding-left: 10px;
    padding-right: 10px;
  }
  .sideImg {
    top: -45px;
    right: -50px;
    width: 130px;
    height: 130px;
  }
}

@media (max-width: 400px) {
  .heading h1 {
    padding-top: 40px;
    text-align: center;
    font-size: 32px;
    padding-left: 10px;
    padding-right: 10px;
  }
}
