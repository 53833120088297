.modalBody {
}

.modalBody {
  /* overflow-y: scroll;
  scrollbar-width: thin;
  --ytd-scrollbar-width: 8px; */
  scrollbar-color: #aaa transparent !important;
}

.modalBody::-webkit-scrollbar {
  width: 10px; /* Width of the scrollbar */
}

.modalBody::-webkit-scrollbar-thumb {
  background-color: gray; /* Color of the scrollbar */
}

.checkbox {
  /* outline: none; */
  background: none !important;
  -webkit-tap-highlight-color: transparent; /* for removing the highlight */
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}
::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 0.5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}
