.loaderOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background: rgba(255, 255, 255, 0.2); */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.loader {
  --s: 40px; /* control the size */
  /* --c: linear-gradient(135deg,#00A8C6,#8FBE00);  */
  --c: linear-gradient(0deg, rgba(202, 255, 0, 0.00) -0.23%, #CAFF00 100.86%);

  display: grid;
  place-items: center;
  place-content: center;
  margin: 0 calc(var(--s)/2);
}
.loader:before,
.loader:after{
  content: "";
  grid-area: 1/1;
}
.loader,
.loader:before,
.loader:after{
  height: var(--s);
  width: calc(var(--s)/5);
  border-radius: var(--s);
  background: var(--c) calc(50% + var(--_i,0)*50%)/var(--s) var(--s);
  transform: translate(calc(var(--_i,0s)*200%));
  animation: l .6s infinite alternate calc(var(--_i,0)*.3s) both;
}
.loader:before {--_i: -1}
.loader:after  {--_i:  1}

@keyframes l {
  from {height: calc(var(--s)/2)}
}


