.swiper .aiPowered {
  padding: 0px;
}

.aiPowered .swiper-slide {
  width: 100% !important;
  margin: auto !important;
}



