.mainContainer {
  display: flex;
  justify-content: center;
  width: 100%;
}

.subContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 93%;
}

.mainHeading {
  width: 100%;
  max-width: 1490px;
  color: #fff;
  text-align: center;
  font-family: TelegraphLight;
  font-size: 88px;
  font-style: normal;
  font-weight: 200;
  line-height: 100%; /* 104.444% */
  text-transform: uppercase;
}
.mainHeading > br {
  display: none;
}

.itemContainer {
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 1490px;
}

.leftContainer {
  width: 46%;
}

.rightContainer {
  /* padding-top: 20px; */
  width: 50%;
}

.rightContainer img {
  width: 100%;
  height: 100%;
}
.mainHeading span {
  background-color: #5937da;
  border-radius: 5px;
  padding-top: 10px;

  padding-right: 20px;
  border-radius: 4px;
}
.accordionContainer {
  overflow: hidden;
}

.accordionContainer h4 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #fff;

  font-family: TelegraphReg;
  font-size: 38px;
  font-style: normal;
  font-weight: 400;
  line-height: 38px; /* 68.421% */
  letter-spacing: 0.866px;
  padding: 32px 37px 23px 37px;

  margin: 0;
  cursor: pointer;

  transition: background-color 0.3s ease;
  border-bottom: 1px solid #ababab;
}

.subContainer h4.active {
  color: black; /* color when active */
  background: #c2ea2b;
  border-radius: 10px 10px 0px 0px;
  border-bottom: 0px solid #ababab;
  padding: 24px 37px 12px 37px;
}

.subContainer h4.active span {
  transform: rotate(180deg);
}

.subContainer h4.active:hover {
  background: rgb(194, 234, 43, 0.9);
}
.accordionContainer .arrow {
  float: right;
  display: flex;
  border: 1px solid rgb(171, 171, 171, 0.4);
  border-radius: 4px;

  transition: transform 0.3s ease;
}

.accordionContainer .arrow.down {
  background-color: #050a02;
  border: 1px solid rgb(171, 171, 171, 0.4);
}
.mainContainer h2 {
  color: white;

  padding: 10px 15px;
  margin: 0;
  cursor: pointer;
  font-size: 18px;
  transition: background-color 0.3s ease;
}

.accordionContainer h4:hover {
  background-color: rgb(169, 169, 169, 0.4);
}
.accordionContainer .content {
  max-height: 0;

  display: none;
  padding: 15px;

  background: #c2ea2b;
  transition: max-height 1s ease-in;
  border-bottom: 0px !important;

  color: #050a02;
  font-family: TelegraphReg;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px; /* 187.5% */
}

.content p {
  padding-bottom: 31px;
}
.subHeading {
  color: #050a02;

  font-family: TelegraphReg;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px; /* 125% */
}
.accordionContainer .content.show {
  display: flex;
  flex-direction: column;
  max-height: 100vh; /* you can adjust this value */
  padding: 0px 37px 5px 37px;
  border-radius: 0px 0px 10px 10px;
  transition: max-height 1s ease-in;
  border-bottom: 0px !important;
}

@media (max-width: 1480px) {
  .mainContainer {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .subContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 93%;
    max-width: 780px;
  }

  .mainHeading {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 1490px;
    color: #fff;
    text-align: center;
    font-family: TelegraphLight;
    font-size: 70px;
    font-style: normal;
    font-weight: 200;
    line-height: 100%; /* 104.444% */
    text-transform: uppercase;
  }

  .mainHeading span {
    background-color: #5937da;
    border-radius: 5px;
    padding-top: 0px;
    padding-right: 0px;

    border-radius: 4px;
    width: fit-content;
  }
  .mainHeading > br {
    display: block;
  }
  .itemContainer {
    margin-top: 50px;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 1490px;
    row-gap: 50px;
  }

  .leftContainer {
    width: 100%;
  }

  .rightContainer {
    /* padding-top: 20px; */
    width: 100%;
  }

  .rightContainer img {
    width: 100%;
    height: 100%;
  }

  .accordionContainer {
    overflow: hidden;
  }

  .accordionContainer h4 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #fff;

    font-family: TelegraphReg;
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 68.421% */
    letter-spacing: 0.866px;
    padding: 14px 5px 14px 20px;

    margin: 0;
    cursor: pointer;

    transition: background-color 0.3s ease;
    border-bottom: 1px solid #ababab;
  }

  .subContainer h4.active {
    color: black; /* color when active */
    background: #c2ea2b;
    border-radius: 10px 10px 0px 0px;
    border-bottom: 0px solid #ababab;
    padding: 14px 20px 14px 20px;
  }

  .subContainer h4.active span {
    transform: rotate(180deg);
  }

  .subContainer h4.active:hover {
    /* background: rgb(194, 234, 43, 0.9);
       */
    background: #c2ea2b;
  }
  .accordionContainer .arrow {
    float: right;
    display: flex;
    border: 1px solid #fff;
    border-radius: 4px;
    width: 40px;
    height: 40px;
    transition: transform 0.3s ease;
  }

  .accordionContainer .arrow.down {
    border: none;
  }
  .mainContainer h2 {
    color: white;

    padding: 10px 15px;
    margin: 0;
    cursor: pointer;
    font-size: 18px;
    transition: background-color 0.3s ease;
  }

  .accordionContainer h4:hover {
    background-color: #050a02;
  }
  .accordionContainer .content {
    max-height: 0;

    display: none;
    padding: 15px;
    /* border-top: 1px solid #ddd; */
    background: #c2ea2b;
    transition: max-height 1s ease-in;
    border-bottom: 0px !important;

    color: #050a02;
    font-family: TelegraphReg;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px; /* 187.5% */
  }
  .subHeading {
    color: #050a02;

    font-family: TelegraphReg;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px; /* 125% */
  }
  .accordionContainer .content.show {
    display: flex;
    flex-direction: column;
    max-height: 100vh; /* you can adjust this value */
    padding: 0px 20px 0px 20px;
    border-radius: 0px 0px 10px 10px;
    transition: max-height 1s ease-in;
    border-bottom: 0px !important;
  }

  .content p {
    margin-top: 10px !important;
  }
}

@media (max-width: 780px) {
  .mainContainer {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .subContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 93%;
  }

  .mainHeading {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 600px;
    color: #fff;
    text-align: center;
    font-family: TelegraphLight;
    font-size: 45px;
    font-style: normal;
    font-weight: 200;
    line-height: 100%; /* 104.444% */
    text-transform: uppercase;
    /* white-space: pre; */
  }

  .mainHeading span {
    background-color: #5937da;
    border-radius: 5px;
    padding-top: 0px;
    padding-right: 0px;

    border-radius: 4px;
    width: fit-content;
    /* border: 1px solid #ababab; */
  }
  .mainHeading > br {
    display: block;
  }
  .itemContainer {
    margin-top: 50px;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 1490px;
    row-gap: 50px;
  }

  .leftContainer {
    width: 100%;
  }

  .rightContainer {
    /* padding-top: 20px; */
    width: 100%;
  }

  .rightContainer img {
    width: 100%;
    height: 100%;
  }

  .accordionContainer {
    overflow: hidden;
  }

  .accordionContainer h4 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #fff;

    font-family: TelegraphReg;
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 68.421% */
    letter-spacing: 0.866px;
    padding: 14px 5px 14px 20px;

    margin: 0;
    cursor: pointer;

    transition: background-color 0.3s ease;
    border-bottom: 1px solid #ababab;
  }

  .subContainer h4.active {
    color: black; /* color when active */
    background: #c2ea2b;
    border-radius: 10px 10px 0px 0px;
    border-bottom: 0px solid #ababab;
    padding: 14px 20px 14px 20px;
  }

  .subContainer h4.active span {
    transform: rotate(180deg);
  }

  .subContainer h4.active:hover {
    /* background: rgb(194, 234, 43, 0.9);
       */
    background: #c2ea2b;
  }
  .accordionContainer .arrow {
    float: right;
    display: flex;
    border: 1px solid #fff;
    border-radius: 4px;
    width: 40px;
    height: 40px;
    transition: transform 0.3s ease;
  }

  .accordionContainer .arrow.down {
    /* transform: rotate(90deg); */

    border: none;
  }
  .mainContainer h2 {
    color: white;
    /* background-color: #f5f5f5; */
    padding: 10px 15px;
    margin: 0;
    cursor: pointer;
    font-size: 18px;
    transition: background-color 0.3s ease;
  }

  .accordionContainer h4:hover {
    background-color: #050a02;
  }
  .accordionContainer .content {
    max-height: 0;

    display: none;
    padding: 15px;

    background: #c2ea2b;
    transition: max-height 1s ease-in;
    border-bottom: 0px !important;

    color: #050a02;
    font-family: TelegraphReg;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px; /* 187.5% */
  }
  .subHeading {
    color: #050a02;

    font-family: TelegraphReg;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px; /* 125% */
  }
  .accordionContainer .content.show {
    display: flex;
    flex-direction: column;
    max-height: 100vh; /* you can adjust this value */
    padding: 0px 20px 0px 20px;
    border-radius: 0px 0px 10px 10px;
    transition: max-height 1s ease-in;
    border-bottom: 0px !important;
  }

  .content p {
    margin-top: 10px !important;
  }
}
@media (max-width: 580px) {
  .mainContainer {
    padding-top: 0px;
  }
  .accordionContainer h4 {
    font-size: 24px;
  }
}
@media (max-width: 480px) {
  .mainContainer {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .subContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 93%;
  }

  .mainHeading {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 1490px;
    color: #fff;
    text-align: center;
    font-family: TelegraphLight;
    font-size: 38px;
    font-style: normal;
    font-weight: 200;
    line-height: 100%; /* 104.444% */
    text-transform: uppercase;
    /* white-space: pre; */
  }

  .mainHeading span {
    background-color: #5937da;
    /* border-radius: 5px; */
    padding-top: 0px;
    padding-right: 0px;

    border-radius: 4px;
    width: fit-content;
    /* border: 1px solid #ababab; */
  }
  .mainHeading > br {
    display: block;
  }
  .itemContainer {
    margin-top: 11.628vw;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 1490px;
    row-gap: 11.628vw;
  }

  .leftContainer {
    width: 100%;
  }

  .rightContainer {
    /* padding-top: 20px; */
    width: 100%;
  }

  .rightContainer img {
    width: 100%;
    height: 100%;
  }

  .accordionContainer {
    overflow: hidden;
  }

  .accordionContainer h4 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #fff;

    font-family: TelegraphReg;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 68.421% */
    letter-spacing: 0.866px;
    padding: 3.256vw 1.163vw 3.256vw 4.651vw;

    margin: 0;
    cursor: pointer;

    transition: background-color 0.3s ease;
    border-bottom: 1px solid #ababab;
  }

  .subContainer h4.active {
    color: black; /* color when active */
    background: #c2ea2b;
    border-radius: 2.326vw 2.326vw 0px 0px;
    border-bottom: 0px solid #ababab;
    padding: 3.256vw 4.651vw 3.256vw 4.651vw;
  }

  .subContainer h4.active span {
    transform: rotate(180deg);
  }

  .subContainer h4.active:hover {
    /* background: rgb(194, 234, 43, 0.9);
       */
    background: #c2ea2b;
  }
  .accordionContainer .arrow {
    float: right;
    display: flex;
    border: 1px solid #fff;
    border-radius: 4px;
    width: 9.302vw;
    height: 9.302vw;
    transition: transform 0.3s ease;
  }

  .accordionContainer .arrow.down {
    /* transform: rotate(90deg); */

    border: none;
  }
  .mainContainer h2 {
    color: white;
    /* background-color: #f5f5f5; */
    padding: 2.326vw 3.488vw;
    margin: 0;
    cursor: pointer;
    font-size: 4.186vw;
    transition: background-color 0.3s ease;
  }

  .accordionContainer h4:hover {
    background-color: #050a02;
  }
  .accordionContainer .content {
    max-height: 0;

    display: none;
    padding: 3.488vw;
    /* border-top: 1px solid #ddd; */
    background: #c2ea2b;
    transition: max-height 1s ease-in;
    border-bottom: 0px !important;

    color: #050a02;
    font-family: TelegraphReg;
    font-size: 3.721vw;
    font-style: normal;
    font-weight: 400;
    line-height: 6.977vw; /* 187.5% */
  }
  .subHeading {
    color: #050a02;

    font-family: TelegraphReg;
    font-size: 5.116vw;
    font-style: normal;
    font-weight: 400;
    line-height: 6.977vw; /* 125% */
  }
  .accordionContainer .content.show {
    display: flex;
    flex-direction: column;
    max-height: 100vh; /* you can adjust this value */
    padding: 0px 4.651vw 0px 4.651vw;
    border-radius: 0px 0px 2.326vw 2.326vw;
    transition: max-height 1s ease-in;
    border-bottom: 0px !important;
  }

  .content p {
    margin-top: 2.326vw !important;
  }
}
