.mainContainer {
  margin-top: 70px;
  display: flex;
  justify-content: center;
  width: 100%;
}
.subContainer {
  display: flex;
  gap: 50px;
  align-items: center;
  width: 90%;
  max-width: 1550px;
}
.sliderContainer {
  margin-left: 0;
  max-width: 780px;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
}

.slider {
  /* width: 100%; */
  overflow: hidden;
  background: #0a0c0a;
  display: flex;
  flex-direction: column;
  gap: 9px;
  /* padding: 8px; */
  border-radius: 47px;

  /* align-items: center; */
}

.card {
}
.border {
  background: linear-gradient(to right, #353a33 0%, #121410 100%);
  padding: 1.3px;
  border-radius: 47px;
  width: 100%;
  overflow: hidden;
}

.sliderBorderArrow {
  position: absolute;
  right: -25px;
  z-index: 1;
  background: linear-gradient(to right, #353a33 0%, #121410 100%);
  padding: 1.5px;
  border-radius: 47px;
  overflow: hidden;
  top: 50%;
  transform: translateY(-50%);
}

.sliderArrow {
  width: 100%;
  padding: 5px;
  border-radius: 47px;

  background-color: black;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  row-gap: 6px;
}
.Mcard {
  background: #0a0c0a;
  padding: 57px;
  border-radius: 47px;
  width: 100%;
  height: 100%;
}

.Mcard img {
  width: 100%;
  height: 100%;
}

.dashboardImg {
  width: 100%;
}
.sliderBorder {
  background: linear-gradient(to right, #353a33 0%, #121410 100%);
  padding: 1px;
  border-radius: 999px;
  overflow: hidden;
  position: absolute;
  right: -20px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
}
/* .slider {
  background: #0a0c0a;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  padding: 5px;
  border-radius: 9999px;
} */
.iconContainerLeft {
  border-radius: 50%;
  padding: 20px;
  border: 1px solid #c2ea2b;
  background-color: #c2ea2b;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 40px;
  height: 40px;
}

.iconContainerRight {
  border-radius: 50%;
  padding: 20px;
  border: 1px solid #c2ea2b;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 40px;
  height: 40px;
}

.contentContainer {
  /* flex-basis: 40% !important; */
  display: flex;
  flex-direction: column;
  color: #fff;
}
.heading {
  max-width: 708px;
}
.heading {
  color: #fff;

  font-family: TelegraphLight;
  font-size: 62.21px;
  font-style: normal;
  font-weight: 200;
  line-height: 100%; /* 62.21px */
  text-transform: uppercase;
}

.heading span {
  background-color: #6d28d9;
  width: max-content;
  border-radius: 5px;
  display: flex;
  /* padding: 2px; */
}
.subHeading {
  margin-top: 28px;
  margin-bottom: 45px;
}
.subHeading p {
  color: #fff;

  font-family: TelegraphLight;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  min-height: 38px;
  line-height: 38px; /* 158.333% */
  text-transform: capitalize;
  /* opacity: 0;
  transform: translateY(100%);
  transition: opacity 1s ease-out, transform 1s ease-out; */
}

/* .subHeading.visible p {
  opacity: 1;
  transform: translateY(0);
} */
.button {
  display: flex;
  max-width: 240px;
  width: 100%;
  padding: 20px 55px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 58px;
  background: #c2ea2b;
  border: 1px solid #c2ea2b;
  box-sizing: border-box;
  color: #050a02;
  font-family: TelegraphBold;
  font-size: 16px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  letter-spacing: 0.64px;
  text-decoration: none;
  transition: all 0.3s ease;
}

.button:hover {
  transform: translateY(-5px);
  filter: brightness(90%);
  transition: all 0.4s ease-in-out;
}

@media (max-width: 1600px) {
  .heading {
    color: #fff;

    font-family: TelegraphLight;
    font-size: 50px;
    font-style: normal;
    font-weight: 200;
    line-height: 100%; /* 62.21px */
    text-transform: uppercase;
  }
  .hightLight {
    background-color: #6d28d9;
    width: max-content;
    border-radius: 5px;
    /* padding: 2px; */
  }
  .subHeading {
    margin-top: 25px;
    margin-bottom: 40px;
  }
  .subHeading p {
    color: #fff;

    font-family: TelegraphLight;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 38px; /* 158.333% */
    min-height: 38px;
    text-transform: capitalize;
  }
}

@media (max-width: 1280px) {
  .subContainer {
    flex-direction: column;
    position: relative;
  }
  /* .Mcard {
    background: #0a0c0a;
    padding: 45px;
    border-radius: 47px;
  } */

  .contentContainer {
    align-items: center;
  }
  .sliderBorderArrow {
    position: absolute;
    bottom: -25px;
    z-index: 1;
    background: linear-gradient(to right, #353a33 0%, #121410 100%);
    padding: 1.5px;
    border-radius: 47px;
    overflow: hidden;
    right: 50%;
    top: unset;
    transform: translateX(50%);
  }

  .sliderArrow {
    width: 100%;
    padding: 5px;
    border-radius: 47px;

    background-color: black;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    column-gap: 6px;
  }

  .heading {
    text-align: center;
  }
  .heading span {
    display: inline;
  }
}

@media (max-width: 900px) {
  .subContainer {
    width: 90%;
    justify-content: center;
  }

  .sliderContainer {
    /* padding: 55px; */

    /* width: 90%; */
    width: 90%;
  }

  .slider {
    width: 100%;
  }

  .heading {
    font-size: 40px;
    max-width: 600px;
  }
  .subHeading p {
    font-size: 18px;
    line-height: 32px;
    min-height: 32px;
  }
  .sliderBorderArrow {
    position: absolute;
    bottom: -25px;
  }
}

@media (max-width: 720px) {
  .sliderContainer {
    padding: 0px;
  }
  .border {
    border-radius: 25px;
  }
  .slider {
    border-radius: 25px;
  }

  .sliderBorderArrow {
    position: absolute;
    bottom: -25px;
  }

  .Mcard {
    padding: 40px;
  }
}

@media (max-width: 480px) {
  /* .sliderContainer {
    padding: 16px;
  } */

  .Mcard {
    padding:40px 20px;
  }

  .sliderBorderArrow {
    position: absolute;
    bottom: -25px;
  }
}
